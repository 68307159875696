import * as React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/layouts/core';

import colors from '../colors/colors';

const ogDescription = `Boogie-Woogie is an NFT collection from Dandy Lines.
We select works of art as inspiration to produce unique abstract representations 
using generative code. 
Controlling color selection and scale ensures the 
random output of the Boogie-Woogie NFT is unique and captures the essence of the original.`;

const TermsPage = () => (
  <>
    <Helmet>
      <title>Boogie-Woogie Terms &amp; Conditions</title>
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@BoogieWoogieNFT' />
      <meta name='twitter:creator' content='@ics_jim' />
      <meta property='og:url' content='https://boogie-woogie.io/terms' />
      <meta property='og:title' content='Boogie-Woogie Terms &amp; Conditions' />
      <meta property='og:type' content='website' />
      <meta property='og:description' content={ogDescription} />
      <meta property='og:image' content='https://sale.boogie-woogie.io/boogie-woogie-auction-site-run-one.png' />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />
    </Helmet>
    <Layout>
      <div style={{ margin: '0 auto', padding: 'calc(1rem + 5vw)', maxWidth: '45rem' }}>
        <h1 style={{ fontSize: '1.25rem', color: colors.red }}>Terms &amp; Conditions</h1>
        <p>Boogie-Woogie is a collection of up to 1970 digital artworks, or NFTs, running on the Ethereum network.</p>
        <p>
          This website and all Boogie-Woogie auctions sites are an interface allowing participants to exchange digital
          collectibles.
        </p>
        <p>
          Users are responsible for the safety and management of their own private Ethereum wallets and are responsible
          for validating all transactions and contracts generated by this and all Boogie-Woogie auction sites before
          approval.
        </p>
        <p>The Ethereum network offers no ability to undo, reverse, or restore any transactions.</p>
        <p>
          This website, all Boogie-Woogie auctions sites, and all connected services are provided 'as is' and 'as
          available' without warranty of any kind.
        </p>
        <p>
          By using this website you are accepting sole responsibility for any and all transactions involving
          Boogie-Woogie digital collectibles.
        </p>
        <h2 style={{ fontSize: '1.25rem' }}>Owning a Boogie-Woogie</h2>
        <h3>You Own the NFT</h3>
        <p>Each Boogie-Woogie is an NFT on the Ethereum blockchain.</p>
        <p>When you purchase an NFT, you own the underlying art.</p>
        <p>
          Ownership of the NFT is mediated in its entirety by the associated Smart Contract and Ethereum Network: at no
          point may we seize, freeze, or otherwise modify the ownership of any Boogie-Woogie asset.
        </p>
        <h3>Personal Use</h3>
        <p>
          Subject to your continued compliance with these Terms, Dandy Lines grants you a worldwide, royalty-free
          license to use, copy, and display the purchased Boogie-Woogie art, along with any extensions that you choose
          to create or use, solely for the following purposes:
        </p>
        <ul>
          <li>
            As part of a marketplace that permits the purchase and sale of your Boogie-Woogie NFT, provided the
            marketplace cryptographically verifies each Boogie-Woogie owner’s right to display the Boogie-Woogie art,
            thus ensuring that only the verified owner can display the Boogie-Woogie art.
          </li>
          <li>
            As part of a third party website or application that permits the inclusion, involvement, or participation of
            your Boogie-Woogie NFT, provided:
            <ul>
              <li>
                The website or application cryptographically verifies each Boogie-Woogie owner’s right to display the
                Boogie-Woogie art, thus ensuring that only the verified owner can display the Boogie-Woogie art
              </li>
              <li>The Art is no longer visible once the owner of the Boogie-Woogie leaves the website/application.</li>
            </ul>
          </li>
          <li>For any personal, non-commercial use.</li>
        </ul>
        <h3>Commercial Use</h3>
        <p>
          Subject to your continued compliance with these Terms, Dandy Lines grants you a worldwide license to use,
          copy, and display the purchased Boogie-Woogie art for the purpose of creating derivative works based on the
          Boogie-Woogie art.
        </p>
        <p>
          Examples of Commercial Use would be the use of the Boogie-Woogie art to produce and sell merchandise products
          displaying copies of the Boogie-Woogie art.
        </p>
        <p>Nothing in this section will be deemed to restrict you from:</p>
        <ul>
          <li>
            Owning or operating a marketplace that permits the use and sale of Boogie-Woogies generally, provided:
            <ul>
              <li>
                The website or application cryptographically verifies each Boogie-Woogie owner’s right to display the
                Boogie-Woogie art, thus ensuring that only the verified owner can display the Boogie-Woogie art
              </li>
            </ul>
          </li>
          <li>
            Owning or operating a third party website or application that permits the inclusion, involvement, or
            participation of Boogie-Woogies, provided:
            <ul>
              <li>
                The website or application cryptographically verifies each Boogie-Woogie owner’s right to display the
                Boogie-Woogie art, thus ensuring that only the verified owner can display the Boogie-Woogie art
              </li>
              <li>The Art is no longer visible once the owner of the Boogie-Woogie leaves the website/application.</li>
            </ul>
          </li>
          <li>Earning revenue from any of the foregoing.</li>
        </ul>
      </div>
    </Layout>
  </>
);

export default TermsPage;
